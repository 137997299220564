import { IconCloudUpload } from "@tabler/icons-react";
import React, { useState, ChangeEvent, DragEvent } from "react";

const UploadScreen = ({handleFileUpload , fileName,errorMessage}:{handleFileUpload: (file: any) => void,fileName:any,errorMessage:any }) => {
    const [dragging, setDragging] = useState(false);
    const [errorMsg, setErrorMessage] = useState<string | null>(null);
  
    const handleDragOver = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setDragging(true);
      };
    
      const handleDragLeave = (): void => {
        setDragging(false);
      };
    
      const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setDragging(false);
        if (e.dataTransfer.files.length > 0) {
          handleFileUpload(e.dataTransfer.files[0]);
        }
      };

      const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0];
          if (file.type === "application/pdf") {
            handleFileUpload(file);
          } else {
            setErrorMessage("Invalid file type. Please upload a PDF file.");
          }
        }
      };

    //   const handleFileUpload = (file: File): void => {
    //     if (file.type !== "application/pdf") {
    //       setErrorMessage("Invalid file type. Please upload a PDF file.");
    //       return;
    //     }
    //     setPdfFile(file);
    //     setErrorMessage(null); // Clear error message
    //     setFileName(file.name);
    //     const fileData = new FormData();
    //     fileData.append("file", file);
    //     console.log("fileData", fileData);
    //     const response = axios
    //       .post(`${apiUrl}/app/extract-risks`, fileData, {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //         },
    //       })
    //       .then((response) => {
    //         console.log(response);
    //         setData(response.data.data);
    //       })
    //       .catch(function (error) {
    //         console.log(error.response?.data.detail);
    //       });
    //     console.log("response", response);
    //     // console.log("pdfFile",pdfFile)
    //     // console.log("url",fileUrl)
    //   };
    
  return(
    <>
            <span className="text-md font-medium mt-16 mb-1 ">Upload a PDF file</span>
            <div className="flex items-center justify-center w-full">
              <div
                className={`w-full max-w-2xl  ${
                  dragging ? "border-blue-500" : "border-gray-300"
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  id="file-upload"
                  className="hidden"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="file-upload"
                  className="flex flex-col items-center justify-center h-full cursor-pointer"
                >
                  <div className="relative flex items-center justify-between p-2 w-full h-24 border rounded-lg bg-sky-50">
                    <div className="flex items-center">
                      <IconCloudUpload
                        stroke={2}
                        color="grey"
                        // color="#3b82f6"
                        className="h-14 w-14"
                      />
                      <div className="ml-4 flex flex-col">
                        <span className=" mt-6 font-medium ">
                          Drag & drop file here.
                        </span>
                        <span className="text-gray-500 ">
                          limit 200MB per file .PDF
                        </span>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() =>
                        document.getElementById("file-upload")?.click()
                      }
                      className={`mt-6 w-36 px-4 py-2 text-white rounded-lg  ${
                        fileName
                          ? "bg-blue-300"
                          : "bg-blue-700 hover:bg-blue-500"
                      }`}
                      disabled={fileName ? true : false}
                    >
                      Browse Files
                    </button>
                  </div>
                  {(errorMessage || errorMsg) && (
                    <p className="mt-4 text-red-500">{errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
          </>
  )
  };

  export default UploadScreen;