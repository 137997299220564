import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { IconCloudUpload, IconFileTypePdf, IconX } from "@tabler/icons-react";
import ViewMultipleFileExtract from "./ViewMultipleFileExtract";
import { useNavigate } from "react-router-dom";
import Header from "../dashboard/Header";

interface FileWithPreview {
  file: File;
  preview: string;
}

const MultipleFileUpload: React.FC = () => {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState("");
  const [isView, setIsView] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const fileList = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setFiles((prevFiles) => [...prevFiles, ...fileList]);
  }, []);

  const accept = {
    "application/pdf": [],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  });

  const handleRemove = (fileToRemove: FileWithPreview) => {
    setFiles(files.filter((file) => file.file.name !== fileToRemove.file.name));
    URL.revokeObjectURL(fileToRemove.preview);
  };

  const handleMultipleUpload = async () => {
    setIsView(true);
    setLoading(true);
    setError(null);
    const formData = new FormData();
    files.forEach(({ file }) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(`${apiUrl}/upload_pdfs`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      setData(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      files.forEach(({ preview }) => URL.revokeObjectURL(preview));
    };
  }, [files]);

  const handleClear = () => {
    setFiles([]);
    setData("");
    setIsView(false);
    navigate("/dashboard");
  };

  return (
    <div className="h-screen">
      <Header />
      <div className="p-4">
        <button
          type="button"
          onClick={handleClear}
          className="px-4 py-2 w-24 bg-blue-700 text-white rounded-lg hover:bg-blue-500 flex items-center justify-center"
        >
          Back
        </button>
      </div>
      <div className="flex flex-col items-center">
        <div {...getRootProps({ className: "w-full max-w-2xl" })}>
          {/* <div className="mt-14"> */}
          <div>
            <span className="text-4xl font-bold text-blue-700">
              Broadridge AI Analysis Risks Extraction
            </span>
            <h1 className="mt-8  text-lg font-semibold text-blue-700">
              Upload PDFs
            </h1>
          </div>

          <input {...getInputProps()} />
          <label
            htmlFor="file-upload"
            className="flex flex-col  items-center justify-center h-full cursor-pointer"
          >
            <div className="relative flex items-center justify-between p-2 w-full h-24 border rounded-lg bg-sky-50">
              <div className="flex items-center">
                <IconCloudUpload
                  stroke={2}
                  color="grey"
                  className="h-14 w-14"
                />
                <div className="ml-4 flex flex-col">
                  <span className="font-medium">Drag & drop PDFs here.</span>
                </div>
              </div>
              <button
                type="button"
                disabled={data !== "" || loading}
                onClick={() => document.getElementById("file-upload")?.click()}
                className={`w-36 px-4 py-2 text-white rounded-lg bg-blue-500 ${
                  data !== "" || loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                Browse Files
              </button>
            </div>
          </label>
        </div>
        <div className="flex flex-col items-center mt-4">
          <div className="flex space-x-4">
            <button
              type="button"
              onClick={handleMultipleUpload}
              disabled={loading || files.length < 2}
              className={`mt-2 w-36 px-4 py-2 text-white rounded-lg bg-blue-500 ${
                data !== "" || files.length < 2
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              } `}
            >
              {loading ? "Comparing..." : "Compare"}
            </button>
            <button
              type="button"
              onClick={handleClear}
              disabled={files.length === 0}
              className={`mt-2 w-36 px-4 py-2 text-white rounded-lg bg-blue-500 ${
                loading || files.length === 0
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              Clear
            </button>
          </div>
        </div>
        {error && <div className="text-red-500 mt-2">{error}</div>}

        {/* Display Uploaded Files */}
        <div className="flex items-center justify-center h-full w-full p-4">
          <div className="flex flex-col w-full max-w-2xl">
            {files.map(({ file, preview }) => (
              <div
                key={file.name}
                className="relative flex items-center justify-between p-2 mb-2 w-full border rounded-lg bg-sky-50"
              >
                <div className="flex items-center">
                  <IconFileTypePdf stroke={1} className="h-10 w-10" />
                  <div className="ml-4 flex flex-col">
                    <a
                      href={preview}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500"
                    >
                      {file.name}
                    </a>
                  </div>
                </div>
                {!data && (
                  <IconX
                    stroke={1}
                    className="text-gray-600 h-4 w-4 cursor-pointer"
                    onClick={() => handleRemove({ file, preview })}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {files && files.length > 0 && isView && (
        <ViewMultipleFileExtract data={data} />
      )}
    </div>
  );
};

export default MultipleFileUpload;
