import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from '../pages/login/LoginPage';
import DashboardPage from '../pages/dashboard/Dashboard';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import MultipleFileUpload from '../pages/uploadFile/MultipleFileUpload';
import MultipleFileUploadXml from '../pages/uploadFile/analyzeRisksCount/MultipleFileUploadXml';
const AppRoutes = () => {
  return (
    <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute element={<DashboardPage />} />}
          />
          <Route path="/multipleUpload" element={<MultipleFileUpload/>} />
          <Route path="/analyzeRisksCount" element={<MultipleFileUploadXml/>} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
    </AuthProvider>
  );
};

export default AppRoutes;
