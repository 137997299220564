import { IconAlertTriangle, IconX } from "@tabler/icons-react";
import React, { FC } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  showCancelButton?: boolean;
  size?: string;
  children: React.ReactNode;
}

const WarningPopup: FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  showCancelButton,
  size,
  children,
}) => {
  if (!isOpen) return null;

  return (
    <>
      <div className={`fixed inset-0 flex items-center justify-center z-50`}>
        <div className="absolute  inset-0 bg-black bg-opacity-55 flex items-center justify-center z-10 ">
          <div
            className={`bg-white rounded-lg overflow-hidden shadow-xl relative ${size}`}
          >
            <div className={`flex items-center justify-between p-4`}>
              {title && <h2 className="text-lg font-semibold">{title}</h2>}
              <button
                onClick={onClose}
                className="text-gray-600 hover:text-gray-900"
              >
                <IconX stroke={1} />
              </button>
            </div>
            <div className="flex justify-center">
              <IconAlertTriangle stroke={2} color="red" className="w-14 h-14" />
            </div>
            <div className="p-4">{children}</div>

            {showCancelButton && (
              <div className="flex justify-center p-4">
                <button
                  onClick={onClose}
                  className="px-4 py-2 w-24 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  Ok
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WarningPopup;
