import axios from "axios";

export const downloadXmlFile = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await axios.get(`${apiUrl}/download_excel`, {
      responseType: 'blob',
    });
    if (response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const file = new Blob([response.data], { type: ' application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = response?.headers['content-disposition']
        ? response?.headers['content-disposition']?.split('filename=')[1].replace(/['"]/g, '')
        : "risk_summary.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    } else {
      console.error('Unexpected content type:', response.headers['content-type']);
    }
  };