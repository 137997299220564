import React from "react";

interface RiskTableProps {
  title: string;
  riskData: { [key: string]: { [key: string]: number } };
}

const MarketRiskTable: React.FC<RiskTableProps> = ({ title, riskData }) => {
  const pdfTitles = Object.keys(riskData);

  const roundToTwoDecimalPlaces = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  return (
    <div className="mb-4">
      <h2 className="text-lg font-bold mt-4">{title}</h2>
      {pdfTitles.length > 0 && (
        <table className="min-w-full border-collapse border border-gray-200">
          <thead>
            <tr>
              <th className="border border-gray-200 px-4 py-2 bg-gray-100"></th>{" "}
              {pdfTitles.map((pdfTitle) => (
                <th
                  key={pdfTitle}
                  className="border border-gray-200 bg-gray-100 px-4 py-2"
                >
                  {pdfTitle}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pdfTitles.map((rowTitle) => (
              <tr key={rowTitle}>
                <td className="border border-gray-200 px-4 py-2">{rowTitle}</td>
                {pdfTitles.map((colTitle) => {
                  const value = roundToTwoDecimalPlaces(
                    riskData[rowTitle][colTitle]
                  );
                  const bgColor = value >= 0.95 ? "bg-green-400" : "";
                  return (
                    <td
                      key={colTitle}
                      className={`border border-gray-200 px-4 py-2 text-center ${bgColor}`}
                    >
                      {value ?? "N/A"}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MarketRiskTable;
