import { useState } from "react";
interface Comparison {
  Differences: string;
  compared_file: string;
  similarity: string;
}
interface RiskSummary {
  source_risk: string;
  risk_name: string;
  risk_differences: Record<string, string>;
  similarity_count: number;
  comparisons: Record<string, Comparison>;
}

const RiskTabs = ({
  riskSummary,
  sourceFileName,
}: {
  riskSummary: RiskSummary[];
  sourceFileName: string;
}) => {
  console.log("riskSummary RiskTabs", riskSummary);
  const [activeTab, setActiveTab] = useState(0);
  const applyStrikethrough = (text: string): string => {
    return text.replace(
      /<strike>(.*?)<\/strike>/g,
      '<del style="color: red;">$1</del>'
    );
  };
  return (
    <div className="space-y-6">
      <div
        className="flex space-x-4 overflow-x-auto overflow-y-hidden border-b border-gray-300 no-scrollbar mb-4 max-w-full"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "#3b82f6 #f1f1f1",
        }}
      >
        {riskSummary?.map((risk, index) => (
          <button
            key={index}
            className={`py-3 px-6 text-lg font-semibold rounded-t-lg transition duration-300 ease-in-out transform hover:scale-105 whitespace-nowrap ${
              activeTab === index
                ? "text-white bg-blue-600 shadow-lg border-b-4 border-blue-800"
                : "text-gray-600 hover:text-blue-600 hover:bg-gray-200"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {risk.source_risk.split("\n")[0]}{" "}
          </button>
        ))}
      </div>

      <div className="p-6 border border-gray-300 rounded-lg shadow-lg bg-white">
        <h2 className="text-2xl font-bold text-blue-800 mb-3 leading-tight">
          {riskSummary[activeTab]?.source_risk.split("\n")[0]}{" "}
        </h2>
        <p className="text-lg text-gray-800 font-medium mb-6 leading-relaxed">
          {riskSummary[activeTab]?.source_risk.split("\n")[1]}{" "}
        </p>
        {/* Risk Differences */}
        <div>
          <h3 className="font-semibold text-lg">Risk Differences:</h3>
          <ul className="space-y-4 mt-2">
            {Object.entries(riskSummary[activeTab]?.comparisons || {}).map(
              (
                [docName, { compared_file, similarity, Differences }],
                index
              ) => {
                return (
                  <li
                    key={index}
                    className="p-4 bg-gray-100 rounded-md shadow-sm border border-gray-300"
                  >
                    <div className="text-green-600 font-bold text-lg mb-2">
                      <span className="text-gray-700">Source File: </span>
                      <span>{sourceFileName}</span>
                    </div>
                    <div className="mb-2">
                      <span className="font-medium text-gray-800">
                        Compared File:{" "}
                      </span>
                      <span>{compared_file}</span>
                    </div>
                    <div className="mb-2">
                      <span className="font-medium text-gray-800">
                        Similarity:{" "}
                      </span>
                      <span>{similarity}</span>
                    </div>
                    <div className="mb-4">
                      <span className="font-medium text-gray-800">
                        Differences:{" "}
                      </span>
                    </div>
                    <p
                      className="text-sm text-gray-700 whitespace-pre-line"
                      dangerouslySetInnerHTML={{
                        __html: applyStrikethrough(Differences),
                      }}
                    ></p>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RiskTabs;
