import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const savedState = localStorage.getItem('isAuthenticated');
    return savedState ? JSON.parse(savedState) : false;
  });

  const apiUrl = process.env.REACT_APP_API_URL;

  const login = async (email: string, password: string): Promise<boolean> => {
    const credentials = { username: email, password: password };

    try {
      const response = await axios.post(`${apiUrl}/app/login`, credentials, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.status === 200) {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true'); 
        return true; 
      }
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
    return false; 
  };

  // Logout function
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); 
  };

  useEffect(() => {
    const savedState = localStorage.getItem('isAuthenticated');
    if (savedState) {
      setIsAuthenticated(JSON.parse(savedState));
    }
  }, []);

  const value = {
    isAuthenticated,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
